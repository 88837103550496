html {
  font-family: sans-serif; // 1
  text-size-adjust: 100%; // 2
  -webkit-text-size-adjust: 100%; // 2
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-size: $root-font-size !important;
  font-weight: $font-weight-base;
  font-family: $font-family-sans-serif;

  // Tablet mode
  @include media-breakpoint-down(lg) {
    font-size: $root-font-size-lg !important;
  }

  // Mobile mode
  @include media-breakpoint-down(md) {
    font-size: $root-font-size-md !important;
  }

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  a:hover,
  a:active,
  a:focus {
    text-decoration: none !important;
  }
}

body {
  display: flex;
  flex-direction: column;
  color: $body-color;
  // border: solid 20px red !important;
}

body .capitalize-text{
  text-transform: capitalize;
}
::-webkit-input-placeholder { /* WebKit browsers */
  text-transform: none;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
  text-transform: none;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
  text-transform: none;
}
::placeholder { /* Recent browsers */
  text-transform: none;
}
// Angular integration
router-outlet {
  display: none;
}

canvas {
  user-select: none;
}

.modal-historial{
  width: '1200px' !important;
}

.mt-150px {
  margin-top: 150px;
}

.space-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.signature-line {
  width: 60%;
  margin: auto;
  border-bottom: 2px solid #000;
}

.font-w-600{
  font-weight: 600;
}

.btn-fixed {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.min-h-800 {
  min-height: 800px;
}

.large-text {

  white-space: nowrap;
  /* Evita que el texto se divida en múltiples líneas */
  overflow: hidden;
  /* Oculta el contenido que se desborda */
  text-overflow: ellipsis;
 

}